import { isPlatformBrowser } from '@angular/common';
import { Inject, InjectionToken, PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';

export const AI_IMAGE_URL_CONFIG = new InjectionToken<ImageUrlConfig>('AI_IMAGE_URL_CONFIG');

export interface ImageUrlConfig {
    baseUrl: string;
    adoptToDevicePixelRation?: boolean;
}

export interface UrlConfigOptions {
    width?: number;
    height?: number;
}

@Pipe({
    name: 'resolveAiImageUrl',
    standalone: true
})
export class ResolveAiImageUrlPipe implements PipeTransform {
    private readonly platformId = inject(PLATFORM_ID);
    constructor(@Inject(AI_IMAGE_URL_CONFIG) private readonly config: ImageUrlConfig) {}

    transform(name: string | undefined | null, config: UrlConfigOptions = { width: 0, height: 0 }): string {
        let scale = 1;
        if (isPlatformBrowser(this.platformId)) {
            scale = this.config.adoptToDevicePixelRation ? window.devicePixelRatio : 1;
        }
        if (config.width && config.height) {
            return `${this.config.baseUrl}/w/${config.width * scale}/h/${config.height * scale}/${name}`;
        } else if (config.width && !config.height) {
            return `${this.config.baseUrl}/w/${config.width * scale}/h/0/${name}`;
        } else if (!config.width && config.height) {
            return `${this.config.baseUrl}/w/0/h/${config.height * scale}/${name}`;
        } else {
            return `${this.config.baseUrl}/${name}`;
        }
    }
}
